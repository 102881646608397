import {makeStyles, Theme, createStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuItem: {
            fontFamily: "Nunito",
            fontSize: "14px",
            fontWeight: 600,
        },
        menuItemSmall:{
            paddingRight:"10px"
        },
        select: {
            color: "#FFFFFF",
            fontFamily: "Nunito",
            fontSize: "14px",
            fontWeight: 600,
            width: "auto",
            height: "19px",
            "&:before": {
                display: "none",
            },
            "&:after": {
                display: "none",
            },
        },

        icon: {
            fill: "#FFFFFF",
            transform: "scaleY(2) scaleX(1.2)",
            top: "calc(50% - 14px);",
        },
        smallIcon: {
            transform: "scaleY(1.5) scaleX(1)",
            top: "calc(50% - 13px)",

        },
    })
);
