import React, { FunctionComponent } from "react";
import { CircularProgress } from "@material-ui/core";

/**
 * Component Loader
 *
 * @component
 *
 * @example
 * return (
 *   <Loader size={30} containerMinHeight="200px"/>
 * )
 */
export interface MainProps {
	size: number;
	containerMinHeight: string;
}

export const Loader: FunctionComponent<MainProps> = (props) => {
	const { size, containerMinHeight } = props;

	return (
		<div className="loader-component" style={{ minHeight: containerMinHeight }}>
			<CircularProgress size={size} />
		</div>
	);
};

export default React.memo(Loader);
