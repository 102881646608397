import React, { FunctionComponent, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { 
    ReferenceListInterface, 
    ReferenceInterface
} from "../../utils/interfaces/referenceInterface";
import { 
  getReferenceDetail,
  deleteReference
 } from "../../store/actions";
import Loader from "../ui/Loader";
import { referenceHeadCells } from "../../utils/enum/referenceTableHeadCell";
import { AppState } from "../../store/configureStore";
import { ReferenceListActions } from "../../store/types";
import { boundRequestReferences } from "../../store/actions/referenceListAction";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchBar from "material-ui-search-bar";
import { apiUrl } from "../../config/config";
import { FRENCH_ABV, ENGLISH_ABV } from "../../utils/helpers/constants";
import EditReferenceFormComponent from "../forms/EditReferenceFormComponent";
import ReferenceDeleteModal from "../reference/ReferenceDeleteModal"

export interface MainProps {
	t: any;
	i18n: any;
}

interface LinkStateProps {
	references: ReferenceListInterface;
  reference: ReferenceInterface;
	loading: Boolean;
}

interface LinkDispatchProps {
	boundRequestReferences: (page: number, perPage: number, searchKey: string) => void,
  getReferenceDetail: (id:number) => void,
}

type LinkProps = LinkStateProps & LinkDispatchProps & MainProps;

const mapStateToProps = (state: AppState): LinkStateProps => ({
	references: state.referenceListReducer.references,
  reference: state.referenceDetailReducer.reference,
	loading: state.referenceListReducer.loading,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<AppState, {}, ReferenceListActions>
) => ({
	boundRequestReferences: bindActionCreators(boundRequestReferences, dispatch),
  getReferenceDetail:bindActionCreators(getReferenceDetail, dispatch)
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//Table head
interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ReferenceInterface) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    t: any;
    }

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, rowCount, onRequestSort, t } = props;
    const createSortHandler = (property: keyof ReferenceInterface) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
        <TableRow>
            {referenceHeadCells.map((referenceHeadCells) => (
            <TableCell
                key={referenceHeadCells.id}
                align='left'
                sortDirection={orderBy === referenceHeadCells.id ? order : false}
            >
                <TableSortLabel
                active={orderBy === referenceHeadCells.id}
                direction={orderBy === referenceHeadCells.id ? order : 'asc'}
                onClick={createSortHandler(referenceHeadCells.id)}
                >
                {t(referenceHeadCells.label)}
                {orderBy === referenceHeadCells.id ? (
                    <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                ) : null}
                </TableSortLabel>
            </TableCell>
            ))}
        </TableRow>
        </TableHead>
    );
}

const  ReferenceListDataTable: FunctionComponent<LinkProps> = (props) =>  {
  const classes = useStyles();
  const { t, i18n, references, reference, loading } = props;
  const language: "en" | "fr" = i18n.language || FRENCH_ABV;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof ReferenceInterface>('name');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchKey, setSearchKey] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  useEffect(() => {
    props.boundRequestReferences(page+1, rowsPerPage, searchKey);
  }, [page, rowsPerPage, searchKey]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ReferenceInterface) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearchKey = (value: string) => {
    setSearchKey(value);
    props.boundRequestReferences(page+1, rowsPerPage, searchKey);
  }

  const handleSearchCanceled = () => {
    setSearchKey("");
  }

  const handleClickOpenEditModal = (referenceId: number) => {
    props.getReferenceDetail(referenceId);
    setOpen(true);
  };

  const handleClickOpenDeleteModal = (referenceId: number) => {
    props.getReferenceDetail(referenceId);
    setOpenDeleteModal(true);
  };

  const handleCloseEditDialog = () => {
    setOpen(false);
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteModal(false);
  }

  return (
    <div className="dataTable-container">
      <div className={classes.root}> 
        <Paper className={classes.paper}>
            <Toolbar>
              <Typography variant="h6" id="tableTitle" component="div">
                  { t("refernce.table.title") }
              </Typography>
              <SearchBar
                placeholder = {t("refernce.table.search")}
                value={searchKey}
                onChange={handleChangeSearchKey}
                onCancelSearch= {handleSearchCanceled}
              />
            </Toolbar>
            <TableContainer>
              {loading && <Loader size={30} containerMinHeight="600px" />}
              <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
              >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={references.total}
                    t = {t}
                  />
               
                  <TableBody>
                  { stableSort(references.data, getComparator(order, orderBy)).map((row, index) => {
                      return (
                          <TableRow hover key={row.id}>
                            <TableCell align="left" >{row.name[language]} </TableCell>
                            <TableCell align="left">{row.summary[language]}</TableCell>
                            <TableCell align="left">{row.description[language]}</TableCell>
                            <TableCell align="left">{
                                row.order ? row.order : "_"
                            }
                            </TableCell>
                            <TableCell align="left">
                                <div className="reference-image-container"><img src={apiUrl + row.image} alt="refrence-image"/></div>
                            </TableCell>
                            <TableCell align="left">{row.website_name}</TableCell>
                            <TableCell align="left">
                                <div className="button-conatainer">
                                    <button onClick={() => handleClickOpenEditModal(row.id)}> <EditIcon/></button>
                                    <button onClick={() => handleClickOpenDeleteModal(row.id)}> <DeleteIcon className="icon-delete"/></button>
                                </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              labelRowsPerPage={t("reference.table.pagination")}
              component="div"
              count={references.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelDisplayedRows={() => {
                return `${page+1}-${Math.floor(references.total / rowsPerPage) + 1}`;
              }}
            /> 
        </Paper>
        <EditReferenceFormComponent  
          i18n={i18n} 
          open={open} 
          handleCloseDialog={handleCloseEditDialog} 
          data={reference} 
          referenceId={reference.id} 
        />
        <ReferenceDeleteModal
          open={openDeleteModal}
          handleClose={handleCloseDeleteDialog} 
          referenceId={reference.id}
        />
    </div>
  </div>
  );
}

export default React.memo(
  withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(ReferenceListDataTable)
  )
);
