import { LoginFormInterface } from '../../utils/interfaces/loginFormInterface';
import {
    SEND_LOGIN_FORM_REQUEST,
    SEND_LOGIN_FORM_SUCCESS,
    SEND_LOGIN_FORM_FAILURE,
    LoginFormActionTypes,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    LogoutActionTypes
} from '../models/authActions';

interface LoginFormState {
    loading: boolean;
    form: LoginFormInterface;
    error: string;
}

interface LogoutState {
    loading: boolean;
    error: string;
}

const defaultLogoutState: LogoutState = {
    loading: false,
    error: ''
};
  
  
const defaultState: LoginFormState = {
    loading: false,
    form: {} as LoginFormInterface,
    error: '',
};

export const loginFormReducer = (
    state = defaultState,
    action: LoginFormActionTypes
): LoginFormState => {
    switch (action.type) {
        case SEND_LOGIN_FORM_REQUEST:
        return { loading: true, form: {} as LoginFormInterface, error: '' };
        case SEND_LOGIN_FORM_SUCCESS:
        return { loading: false, form: action.form, error: '' };
        case SEND_LOGIN_FORM_FAILURE:
        return { loading: false, form: {} as LoginFormInterface, error: action.error };
        default:
        return state;
    }
};

export const logoutReducer = (
    state = defaultLogoutState,
    action: LogoutActionTypes
): LogoutState => {
    switch (action.type) {
        case LOGOUT_REQUEST:
        return { loading: true,  error: '' };
        case LOGOUT_SUCCESS:
        return { loading: false, error: '' };
        case LOGOUT_FAILURE:
        return { loading: false, error: action.error };
        default:
        return state;
    }
};