import React, {FunctionComponent, useState} from "react";
import {withTranslation} from "react-i18next";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    MAX_LENGTH,
    DESCRIPTION_MAX_LENGTH,
    SUMMARY_MAX_LENGTH,
    IMAGE_MAX_SIZE,
    SCREENSHOTS_MAX_SIZE
} from "../../utils/helpers";
import ReferenceTabComponent from "../reference/ReferenceTabComponent"
import "../../assets/sass/reference.scss";
import {referenceFormInterface, referenceInterfaceData, referenceErrorsInterface} from "../../utils/interfaces";
import Loader from "../ui/Loader";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";

export interface MainProps {
    t: any
}

interface LinkStateProps {
    loading: boolean;
}

interface LinkDispatchProps {
    addReference: (reference: referenceFormInterface) => void,
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
    loading: state.referenceAddReducer.loading,
});

type LinkProps = LinkStateProps & LinkDispatchProps & MainProps;
export const AddReferenceFormComponent: FunctionComponent<LinkProps> = (props) => {
    const [referenceDataFR, setReferenceDataFR] = useState({
            name: '',
            summary: '',
            description: '',
            intervention_description: '',
            strategy_description: '',
            website_name: '',
            category_name: '1'
        }
    );
    const [referenceDataEN, setReferenceDataEN] = useState({
        name: '',
        summary: '',
        description: '',
        intervention_description: '',
        strategy_description: '',
        website_name: '',
        category_name: '1'
    });
    const [language, setLanguage] = useState('fr');
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState("");
    const [imageSize, setImageSize] = useState(0);
    const [screenshots, setScreenshots] = useState<Array<any>>([]);
    const [screenshotsName, setScreenshotsName] = useState("");
    const [screenshotsSize, setScreenshotsSize] = useState(0);
    const [order, setOrder] = useState('');
    const [open, setOpen] = React.useState(false);
    const [valueRadioButton, setValueRadioButton] = React.useState('1');
    const [errorsFR, setErrorsFR] = useState<referenceErrorsInterface>(
        {} as referenceErrorsInterface
    );
    const [errorsEN, setErrorsEN] = useState<referenceErrorsInterface>(
        {} as referenceErrorsInterface
    );
    const [showCompleteFr, setShowCompleteFr] = useState(false);
    const [showCompleteEn, setShowCompleteEn] = useState(false);

    const {addReference, t, loading} = props;

    const validationForm = (values: referenceInterfaceData): referenceErrorsInterface => {
        let errorObj = {} as referenceErrorsInterface;
        if (!values.name) {
            errorObj.name = t("reference.add.name.required");
        } else if (values.name.length > MAX_LENGTH) {
            errorObj.name = t("reference.add.name.long");
        }
        if (!values.summary) {
            errorObj.summary = t("reference.add.summary.required");

        } else if (values.summary.length > SUMMARY_MAX_LENGTH) {
            errorObj.summary = t("reference.add.summary.long");
        }
        if (!values.description) {
            errorObj.description = t("reference.add.description.required");
        } else if (values.description.length > DESCRIPTION_MAX_LENGTH) {
            errorObj.description = t("reference.add.description.long");
        }
        if (!values.website_name) {
            errorObj.website_name = t("reference.add.website.required");
        } else if (values.website_name.length > MAX_LENGTH) {
            errorObj.website_name = t("reference.add.website.long");
        }

        if (!values.category_name) {
            errorObj.category_name = t("reference.add.category.required");
        }
        if (!values.intervention_description) {
            errorObj.intervention_description = t("reference.add.intervention.required");
        } else if (values.intervention_description.length > DESCRIPTION_MAX_LENGTH) {
            errorObj.intervention_description = t("reference.add.intervention.long");
        }
        if (!values.strategy_description) {
            errorObj.strategy_description = t("reference.add.strategy.required");
        } else if (values.strategy_description.length > DESCRIPTION_MAX_LENGTH) {
            errorObj.strategy_description = t("reference.add.strategy.long");
        }
        if (!image) {
            errorObj.image = t("reference.add.image.required")
        } else if (imageSize > IMAGE_MAX_SIZE) {
            errorObj.image = t("reference.add.image.big");
        }
        if (!screenshots.length) {
            errorObj.screenshots = t("reference.add.screenshots.extension")
        } else if (screenshotsSize > SCREENSHOTS_MAX_SIZE) {
            errorObj.screenshots = t("reference.add.screenshots.big");
        }
        return errorObj;
    }


    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueRadioButton((event.target as HTMLInputElement).value);
        setReferenceDataFR(prevReferenceDataFR => ({
                ...prevReferenceDataFR, category_name: (event.target as HTMLInputElement).value
            })
        );
        setReferenceDataEN(prevReferenceDataEN => ({
                ...prevReferenceDataEN, category_name: (event.target as HTMLInputElement).value
            })
        )
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setLanguage("fr");
        emptyFields();
        setOpen(false);
    };

    const handleUploadImageChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
            setImageName(file.name);
            setImageSize(file.size)
        }
    };

    const handleUploadScreenshotsChange = (event: any) => {
        let files = event.target.files;
        let allNames = "";
        let filesArray = [];
        let fileSize = 0;
        for (let file of files) {
            filesArray.push(file);
            fileSize = fileSize + file.size;
            if (!allNames) {
                allNames = file.name;
            } else {
                allNames = allNames + ", " + file.name;
            }
        }
        setScreenshots(filesArray);
        setScreenshotsName(allNames);
        setScreenshotsSize(fileSize);
    };

    const emptyFields = () => {
        setReferenceDataFR({
            name: '',
            summary: '',
            description: '',
            intervention_description: '',
            strategy_description: '',
            website_name: '',
            category_name: '1'
        });
        setReferenceDataEN({
            name: '',
            summary: '',
            description: '',
            intervention_description: '',
            strategy_description: '',
            website_name: '',
            category_name: '1'
        });
        setErrorsFR({} as referenceErrorsInterface);
        setErrorsEN({} as referenceErrorsInterface);
        setScreenshots([]);
        setImage(null);
        setScreenshotsName("");
        setImageName("");
        setOrder('');
        setShowCompleteFr(false);
        setShowCompleteEn(false)
    };

    const showCompleteForm = (language: string, isValidFr: boolean, isValidEn: boolean) => {
        let isFr = language == 'fr';
        if (isFr && isValidFr && !isValidEn) {
            setShowCompleteEn(true);
        }
        if (!isFr && !isValidFr && isValidEn) {
            setShowCompleteFr(true);
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const errorsObjFR = validationForm(referenceDataFR);
        const errorsObjEN = validationForm(referenceDataEN);

        setErrorsFR(errorsObjFR);
        setErrorsEN(errorsObjEN);

        const isValidFr = !Object.keys(errorsObjFR).length;
        const isValidEn = !Object.keys(errorsObjEN).length;
        const isValid = isValidFr && isValidEn;

        showCompleteForm(language, isValidFr, isValidEn);

        if (isValid) {
            const referenceData: referenceFormInterface = {
                "fr": referenceDataFR,
                "en": referenceDataEN,
                "image": image,
                "screenshots": screenshots,
                "order": order
            };
            addReference(referenceData);
            if (!loading) {
                setOpen(false);
            }
            emptyFields();
        }
    }

    const changeValue = (event: any) => {
        if (language == 'fr') {
            setReferenceDataFR({
                ...referenceDataFR,
                [event.target.name]: event.target.value,
            })
            setErrorsFR({...errorsFR, [event.target.name]: ""})
        } else {
            setReferenceDataEN(
                {
                    ...referenceDataEN,
                    [event.target.name]: event.target.value,

                }
            )
            setErrorsEN({...errorsEN, [event.target.name]: ""})
        }

    };
    const handleChangeOrder = (event: any) => {
        setOrder(event.target.value);
    }
    const changeLanguage = (value: string) => {
        setLanguage(value);
        value == "fr" ? setShowCompleteFr(false) : setShowCompleteEn(false); //hide complete form error
    }

    return (
        <div className="add-reference-container">
            <Button
                className="button add-reference"
                onClick={handleClickOpen}
            >
                {t("reference.add.button")}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="dialog-content">
                <DialogTitle id="form-dialog-title">
                    <div className="title">{t("reference.add.modal.title")}</div>
                </DialogTitle>
                <DialogContent>
                    <ReferenceTabComponent
                        referenceDataFR={referenceDataFR}
                        referenceDataEN={referenceDataEN}
                        language={language}
                        changeLanguage={changeLanguage}
                        imageName={imageName}
                        order={order}
                        screenshotsName={screenshotsName}
                        valueRadioButton={valueRadioButton}
                        handleRadioButtonChange={handleRadioButtonChange}
                        handleUploadImageChange={handleUploadImageChange}
                        handleUploadScreenshotsChange={handleUploadScreenshotsChange}
                        changeValue={changeValue}
                        handleChangeOrder={handleChangeOrder}
                        errorsFR={errorsFR}
                        errorsEN={errorsEN}
                    />

                    {showCompleteFr && <div className="complete-form-error">{t("common.complete.form.fr")}</div>}
                    {showCompleteEn && <div className="complete-form-error">{t("common.complete.form.en")}</div>}

                    <DialogActions>
                        <Button onClick={handleClose} className="button add-reference">
                            {t("reference.close.modal.button")}
                        </Button>
                        <Button onClick={handleSubmit} className="button add-reference" disabled={loading}>
                            {t("reference.add.modal.button")}
                            {loading && <Loader size={15} containerMinHeight="100%"/>}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default React.memo(connect(mapStateToProps)(withTranslation()(AddReferenceFormComponent)));