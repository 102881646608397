// form validator
export const email_MAX_LENGTH = 50;
export const PASSWORD_MAX_LENGTH = 20;
export const MAX_LENGTH = 50;
export const DESCRIPTION_MAX_LENGTH = 2000;
export const SUMMARY_MAX_LENGTH = 256;

export const IMAGE_MAX_SIZE = 1048576; //1024 Kilooctet
export const SCREENSHOTS_MAX_SIZE = 2097152; // 2048 kilooctet

// CONSTANT Errors
export const ERROR_NETWORK = 'network error';
export const ERROR_UNKNOWEN = 'unknow-error';

// WS status
export const UNAUTHORISED_STATUS = 401;
export const STATUS_NOT_FOUND = 404;

export const DEFAULT_PER_PAGE = 10;
export const DEFAULT_PAGE = 1;

export const UNITED_KINGDOM = 'uk';
export const TUNISIA = 'tn';
export const FRENCH_ABV = 'fr';
export const ENGLISH_ABV = 'en';