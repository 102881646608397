import axios from "axios";
import {apiUrl} from "../../config/config";
import {UNAUTHORISED_STATUS, history} from "../../utils/helpers";

export const methodsServices = {
    sendLoginForm,
    post,
	get,
	deleteDetail,
    logout,
	getReferenceList
};

//login ws
async function sendLoginForm(
    data: FormData
): Promise<any> {
	return await axios
		.post(`${apiUrl}api/login`, data)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err.response);
		});
}

//logout ws
async function logout(
	headers : boolean
): Promise<any> {
	let authStr = "";
	if (headers) {
		authStr = `Bearer ${localStorage.getItem("token")}`; //Prepare the authorization with the token
	}
	return await axios
		.post(`${apiUrl}api/logout`, {}, {
			headers: { Authorization: authStr },
		})
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err:any) => {
            if (
                err.response &&
                err.response.status &&
                UNAUTHORISED_STATUS == err.response.status
            ) {
                localStorage.clear();
                history.push("/login");
                //todo add "common.unauthorized" message
                return Promise.reject("common.unauthorized");
            } else {
                return Promise.reject(err.response);
            }
	});
}

//return list of reference ws
async function getReferenceList(
	page: number,
	perPage: number,
	searchKey: string,
    headers:boolean = false
): Promise<any> {
    let authStr = "";
    if (headers) {
        authStr = `Bearer ${localStorage.getItem("token")}`; //Prepare the authorization with the token
    }
	return await axios
		.get(`${apiUrl}api/references?page=${page}&per_page=${perPage}&search_key=${searchKey}`,
            {
                headers: {
                    Authorization: authStr,
                },
            }
        )
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err:any) => {
            if (
                err.response &&
                err.response.status &&
                UNAUTHORISED_STATUS == err.response.status
            ) {
                localStorage.clear();
                history.push("/login");
                //todo add "common.unauthorized" message
                return Promise.reject("common.unauthorized");
            } else {
                return Promise.reject(err.response);
            }
		});
}

//return reference detail
async function get(
	apiEndpoint:string,
	headers:boolean = false
): Promise<any> {
	let authStr = "";
    if (headers) {
        authStr = `Bearer ${localStorage.getItem("token")}`; //Prepare the authorization with the token
    }
	return await axios
		.get(`${apiUrl}api/` + apiEndpoint, {
			headers: {
				Authorization: authStr,
			},
		})
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err:any) => {
            if (
                err.response &&
                err.response.status &&
                UNAUTHORISED_STATUS == err.response.status
            ) {
                localStorage.clear();
                history.push("/login");
                //todo add "common.unauthorized" message
                return Promise.reject("common.unauthorized");
            } else {
                return Promise.reject(err.response);
            }
		});
}
/**
 * post method
 *  @param payload: any
 *  @param headers:boolean
 * @param apiEndpoint:string
 */
async function post(apiEndpoint:string, payload: any, headers:boolean = false) {
    let authStr = "";
    if (headers) {
        authStr = `Bearer ${localStorage.getItem("token")}`; //Prepare the authorization with the token
    }
    return await axios
        .post(`${apiUrl}api/`+ apiEndpoint, payload, {
            headers: {Authorization: authStr},
        })
        .then((response:any) => {
            return response;
        })
        .catch((err:any) => {
            if (
                err.response &&
                err.response.status &&
                UNAUTHORISED_STATUS == err.response.status
            ) {
                localStorage.clear();
                history.push("/login");
                //todo add "common.unauthorized" message
                return Promise.reject("common.unauthorized");
            } else {
                return Promise.reject(err.response);
            }
        });
}

/**
 * delete method
 *  @param payload: any
 *  @param headers:boolean
 * @param apiEndpoint:string
 */
 async function deleteDetail(apiEndpoint:string, headers:boolean = false) {
    let authStr = "";
    if (headers) {
        authStr = `Bearer ${localStorage.getItem("token")}`; //Prepare the authorization with the token
    }
    return await axios
        .delete(`${apiUrl}api/` + apiEndpoint , {
            headers: {Authorization: authStr},
        })
        .then((response:any) => {
            return response;
        })
        .catch((err:any) => {
            if (
                err.response &&
                err.response.status &&
                UNAUTHORISED_STATUS == err.response.status
            ) {
                localStorage.clear();
                history.push("/login");
                //todo add "common.unauthorized" message
                return Promise.reject("common.unauthorized");
            } else {
                return Promise.reject(err.response);
            }
        });
}

