import React, {FunctionComponent, useEffect, useState} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {useStyles} from "./styles";
import i18n from "../../i18n";
import {languagesList} from "../../utils/enum/languagesList";

/**
 * Component DropdownComponent
 *
 * @component
 *
 * @example
 * return (
 *   <DropdownComponent/>
 * )
 */
const storageLanguage = localStorage.getItem("language") || "fr";

interface MainProps {
    drawerOpened: boolean
}

export const DropdownComponent: FunctionComponent<MainProps> = (props) => {

    const [Language, setLanguage] = React.useState<string>(storageLanguage);
    const [open, setOpen] = React.useState(false);
    const [screenWidth, setScreenWidth] = useState(window.screen.width);

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [screenWidth]);

    const handleChange = (event: any) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value).then(() => {
            localStorage.setItem("language", event.target.value);
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const classes = useStyles();
    const {drawerOpened} = props;

    const classesdd = () => (drawerOpened && screenWidth < 600) ? {icon: classes.smallIcon} : {icon: classes.icon};

    return (
        <div className="dropdownContainer">
            <Select
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={Language}
                onChange={handleChange}
                className={classes.select}
                style={{
                    fontSize: `${((drawerOpened && screenWidth < 600)) ? '12px' : '14px'}`,
                }}
                inputProps={{
                    classes: classesdd()
                }}

                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    getContentAnchorEl: null,
                }}
            >
                {languagesList.map((item, index) => (
                    <MenuItem
                        className={drawerOpened && screenWidth < 600 ? classes.menuItemSmall : classes.menuItem}
                        value={item.toLowerCase()}
                        key={"language-" + index}
                    >
                        {item.toUpperCase()}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default React.memo(DropdownComponent);
