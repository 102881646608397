import React, {FunctionComponent, useState} from "react";
import {withTranslation} from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../store/configureStore";
import { ReferenceDeleteActions } from "../../store/types";
import { deleteReference } from "../../store/actions/referenceAction";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from "../ui/Loader";

import "../../assets/sass/reference.scss";
import { ReferenceInterface } from "../../utils/interfaces";

export interface MainProps {
    t?:any,
    open:boolean
    handleClose(): void,
    referenceId: number
}

interface LinkStateProps {
	loading: boolean;
}

interface LinkDispatchProps {
	deleteReference: (referenceId: number) => void,
}

type LinkProps = LinkStateProps & LinkDispatchProps & MainProps;

const mapStateToProps = (state: AppState): LinkStateProps => ({
	loading: state.referenceDeleteReducer.loading,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<AppState, {}, ReferenceDeleteActions>
) => ({
	deleteReference: bindActionCreators(deleteReference, dispatch)
});

export const ReferenceDeleteModal: FunctionComponent<LinkProps> = (props) => {
    const { t, open, handleClose, referenceId, loading } = props;

    const handleSubmit = (event: any) => {
        event.preventDefault();
        props.deleteReference(referenceId);
        props.handleClose();
    }

    return (
        <div className="delete-reference-container">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="dialog-content" id="delete-dialog">
                <DialogTitle id="form-dialog-title">
                    <div className="title">{t("reference.delete.modal.title")}</div>
                </DialogTitle>
                <DialogContent>
                    <div className="delete-dialog-content">
                        <div>{t("reference.delete.modal.text1")}</div>
                        <div>{t("reference.delete.modal.text2")}</div>
                         
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose} className="button add-reference">
                            {t("reference.reject.delete")}
                        </Button>
                        <Button 
                            onClick={handleSubmit}  
                            className="button add-reference"
                            disabled={loading}
                        >
                            {t("reference.confirm.delete")}
                            {loading && <Loader size={15} containerMinHeight="100%" />}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default React.memo(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(ReferenceDeleteModal)
    )
);