export interface referenceInterfaceData{
    'name' :string,
    'summary':string,
    'description':string,
    'intervention_description':string,
    'strategy_description':string,
    'website_name':string,
    'category_name':string|number,
}
export interface referenceFormInterface {
    "fr":referenceInterfaceData|{},
    "en":referenceInterfaceData|{},
    'image':any,
    'screenshots':Array<any>,
    'order':any
}
export interface referenceErrorsInterface{
    'name' :string,
    'website_name':string,
    'summary':string,
    'description':string,
    'intervention_description':string,
    'strategy_description':string,
    'category_name':string,
    'image':string,
    'screenshots':string
}

export interface ReferenceInterface {
    id : number,
    name: {
        fr : string,
        en : string,
    },
    summary: {
        fr : string,
        en : string,
    },
    description: {
        fr : string,
        en : string,
    },
    intervention_description: {
        fr : string,
        en : string,
    },
    strategy_description: {
        fr : string,
        en : string,
    },
    image: string,
    screenshot_images: Array<string>,
    website_name: string,
    category_name: {
        fr : string,
        en : string,
    },
    order:any
}

export interface ReferenceListInterface {
    current_page: number, 
    data : Array<ReferenceInterface>,
    first_page_url: string,
    from: number,
    last_page: number,
    last_page_url: string,
    next_page_url: string,
    path: string,
    per_page: number,
    prev_page_url: string,
    to: number,
    total: number,
}

export const emptytReference = (): ReferenceInterface => ({
    id : 1,
    name: {
        fr : '',
        en : '',
    },
    summary: {
        fr : '',
        en : '',
    },
    description: {
        fr : '',
        en : '',
    },
    intervention_description: {
      fr : '',
      en : '',
    },
    strategy_description: {
        fr : '',
        en : '',
    },
    image: '',
    screenshot_images: [],
    website_name: '',
    category_name: {
        fr : '',
        en : '',
    },
    order:''
});

export const emptyReferences = (): ReferenceListInterface => ({
    current_page: 0,
    data: [],
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    next_page_url: '',
    path: '',
    per_page: 0,
    prev_page_url: '',
    to: 0,
    total: 0,
  });

export  interface ReferenceTableHeadCell {
    id: keyof ReferenceInterface;
    label: string;
    numeric: boolean;
  }
