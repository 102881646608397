import {
    ReferenceListActionTypes,
    FETCH_REFERENCES_REQUEST,
    FETCH_REFERENCES_SUCCESS,
    FETCH_REFERENCES_FAILURE,
    ReferenceDetailActionTypes,
    GET_REFERENCE_DETAIL,
    GET_REFERENCE_DETAIL_SUCCESS,
    GET_REFERENCE_DETAIL_FAILURE,
    ReferenceEditActionTypes,
    EDIT_REFERENCE,
    EDIT_REFERENCE_SUCCESS,
    EDIT_REFERENCE_FAILURE,
    ReferenceDeleteActionTypes,
    DELETE_REFERENCE,
    DELETE_REFERENCE_SUCCESS,
    DELETE_REFERENCE_FAILURE,
    ReferenceAddActionTypes,
    ADD_REFERENCE_REQUEST,
    ADD_REFERENCE_SUCCESS,
    ADD_REFERENCE_FAILURE,
} from '../models/referenceActions';
import { 
  ReferenceListInterface,
  emptyReferences,
  ReferenceInterface,
  emptytReference 
} from '../../utils/interfaces/referenceInterface';


interface ReferenceState {
  loading: boolean;
  references: ReferenceListInterface;
  error: string;
}

const defaultState: ReferenceState = {
  loading: false,
  references: emptyReferences(),
  error: '',
};

interface ReferenceDetailState {
  loading: boolean;
  reference: ReferenceInterface;
  error: string;
};

const defaultReferenceState: ReferenceDetailState = {
  loading: false,
  reference: emptytReference(),
  error: '',
};

interface ReferenceEditState {
  loading: boolean;
  error: string;
}

const defaultEditState: ReferenceEditState = {
  loading: false,
  error: '',
};

interface ReferenceDeleteState {
  loading: boolean;
  referenceDeleted: ReferenceInterface;
  error: string;
};

const defaultReferencedeleteState: ReferenceDeleteState = {
  loading: false,
  referenceDeleted: emptytReference(),
  error: '',
};

//add reference state
interface ReferenceAddState {
    loading: boolean;
    error: string;
};

const defaultReferenceAddState: ReferenceAddState = {
    loading: false,
    error: '',
};

export const referenceListReducer = (
  state = defaultState,
  action: ReferenceListActionTypes
): ReferenceState => {
  switch (action.type) {
    case FETCH_REFERENCES_REQUEST:
      return { loading: true, references:  emptyReferences(), error: '' };
    case FETCH_REFERENCES_SUCCESS:
      return { loading: false, references: action.references, error: '' };
    case FETCH_REFERENCES_FAILURE:
      return { loading: false, references:  emptyReferences(), error: action.error };
    default:
      return state;
  }
};

export const referenceDetailReducer = (
  state = defaultReferenceState,
  action: ReferenceDetailActionTypes
  ): ReferenceDetailState => {
  switch (action.type) {
      case GET_REFERENCE_DETAIL:
      return { loading: true, reference:  emptytReference(), error: '' };
      case GET_REFERENCE_DETAIL_SUCCESS:
      return { loading: false, reference: action.reference, error: '' };
      case GET_REFERENCE_DETAIL_FAILURE:
      return { loading: false, reference:  emptytReference(), error: action.error };
      default:
      return state;
  }
};

export const referenceEditReducer = (
  state = defaultEditState,
  action: ReferenceEditActionTypes
  ): ReferenceEditState => {
  switch (action.type) {
      case EDIT_REFERENCE:
      return { loading: true, error: '' };
      case EDIT_REFERENCE_SUCCESS:
      return { loading: false, error: '' };
      case EDIT_REFERENCE_FAILURE:
      return { loading: false, error: action.error };
      default:
      return state;
  }
};

export const referenceDeleteReducer = (
  state = defaultReferencedeleteState,
  action: ReferenceDeleteActionTypes 
  ): ReferenceDeleteState => {
  switch (action.type) {
      case DELETE_REFERENCE:
      return { loading: true, referenceDeleted:  emptytReference(), error: '' };
      case DELETE_REFERENCE_SUCCESS:
        return { loading: false, referenceDeleted: action.referenceDeleted, error: '' };
      case DELETE_REFERENCE_FAILURE:
        return { loading: false, referenceDeleted:  emptytReference(), error: action.error };
      default:
      return state;
  }
};

export const referenceAddReducer = (
    state = defaultReferenceAddState,
    action: ReferenceAddActionTypes
): ReferenceAddState => {
    switch (action.type) {
        case "ADD_REFERENCE_REQUEST":
            return { loading: true, error: '' };
        case ADD_REFERENCE_SUCCESS:
            return { loading: false, error: '' };
        case ADD_REFERENCE_FAILURE:
            return { loading: false, error: action.error };
        default:
            return state;
    }
};