import{ ERROR_NETWORK,UNITED_KINGDOM, TUNISIA,} from "./constants"
/**
 * Returns the first error from the ws response
 *
 * @param {any} e
 *
 * @returns {string}
 */
 export const getFirstErrorFromResponse = (e: any) => {
    const defaultError = "unknow-error";
    let message = "";
    if (typeof e === "object" && e.message) {
        message = e;
    } else if (typeof e === "string") {
        message = e || defaultError;
    } else if (e && e && e.data) {
        let eData = e.data;
        if (eData) {
            const errorsKeys = Object.keys(eData);
            if (errorsKeys.length) {
                if (typeof eData[errorsKeys[0]][0] === "string") {
                    message = eData[errorsKeys[0]][0];
                }
            }
        }
    } else {
        message = ERROR_NETWORK;
    }

    return message || defaultError;
};

/**
 * Confirms if there is a connected user or not
 *
 * @returns {boolean}
 */
export const isLoggedIn = () => {
	return localStorage.getItem("token") ? true : false;
};

/**
 * Verify domin is uk or tn
 */
export function verifyDomain(
): string {
    if (window.location.hostname.indexOf(UNITED_KINGDOM) !== -1) {
        return UNITED_KINGDOM
    }
    return TUNISIA
}

/**
 * Verify domin is uk
 */
export function isUkDomain(
): Boolean {
    return verifyDomain() === UNITED_KINGDOM
}

/**
 * Validate the email format
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const emailIsValid = (value: string): boolean => {
    if (new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(value)) {
        return true;
    } else {
        return false;
    }
};
