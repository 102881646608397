import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import configureStore from "./store/configureStore";
import { history } from "./utils/helpers/history";
import { Router } from "react-router-dom";

let _window: any = window;
const initialState = _window.initialReduxState;

const store = configureStore(history, initialState);

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<Provider store={store}>
			<Router  history={history}>
				<App />
			</Router>
		</Provider>
	</I18nextProvider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();