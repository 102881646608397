import React, { FunctionComponent, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppState } from "../../store/configureStore";
import { LoginFormInterface } from "../../utils/interfaces/loginFormInterface";
import { LoginFormActions } from "../../store/types";
import { boundRequestSendLoginForm } from "../../store/actions/authAction";
import { Button, FormHelperText, Grid, TextField } from "@material-ui/core";
import logoAnypli from "../../assets/images/LogoColor.png";
import { emailIsValid } from "../../utils/helpers/tools";
import { PASSWORD_MAX_LENGTH } from "../../utils/helpers/constants";

import "../../assets/sass/login.scss";

export interface MainProps {
    error?: string;
	t:any;
	i18n: any;
}

interface LinkStateProps {
	form: LoginFormInterface;
	loading: boolean;
	error: string;
}

interface LinkDispatchProps {
	boundRequestSendLoginForm: (data: LoginFormInterface) => any;
}

type LinkProps = MainProps & LinkStateProps & LinkDispatchProps;

const mapStateToProps = (state: AppState): LinkStateProps => ({
	form: state.loginFormReducer.form,
	loading: state.loginFormReducer.loading,
	error: state.loginFormReducer.error,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<AppState, {}, LoginFormActions>
) => ({
	boundRequestSendLoginForm: bindActionCreators(boundRequestSendLoginForm, dispatch),
});

export const LoginFormComponent: FunctionComponent<LinkProps> = (props) => {
	const { t } = props;
    const history = useHistory();
	const [errorMessage, setErrorMessage] = useState('');
    const [values, setValues] = useState({
		email: "",
		password: ""
	});

    const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");

    const validationForm = () => {
        let isValid = true;
		if (!values.email) {
            setEmailError(t("form.email.required"));
            isValid = false;
        } else if (!emailIsValid(values.email)) {
            setEmailError(t("form.email.email"));
            isValid = false;
        }
        if (!values.password) {
			setPasswordError(t("form.password.required"));
			isValid = false;
		} else if (values.password.length > PASSWORD_MAX_LENGTH) {
			setPasswordError(t("form.password.long"));
			isValid = false;
		}
        return isValid;
    }

    const emptyFields = () => {
		setValues({
			email: "",
			password: ""
		});
	};

    const handleSubmit = (event:any) => {
        event.preventDefault();
		if (validationForm()) {
			props
				.boundRequestSendLoginForm({
					email: values.email,
					password: values.password
				})
				.then((res: any) => {
                    if (!res.error) {
						history.push('/');					
					} else {
						emptyFields();
						setErrorMessage(t("form.wrong.credential"));
					}
				});
		}
    }

    const removerErrorMessage = (name: string) => {
		switch (name) {
			case "email":
				setEmailError("");
				setErrorMessage("");
				break;

			case "password":
				setPasswordError("");
				setErrorMessage("");
				break;
			default:
				break;
		}
	};
    
    const ChangeValue = (event: any) => {
		removerErrorMessage(event.target.name);
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};

    return (
		<Grid container className="form_control_container">
			<Grid
				item
				sm={3}
				md={3}
				container
				alignItems="center"
				className="login_form-container"
			>
                <Grid item xs={12} sm={12} className="logo_container">
                    <img src={logoAnypli} alt="logo"/>
                </Grid>
			
                <Grid item xs={12} sm={12} className="form-input">
                        <label>
                            <TextField
                                required
                                fullWidth
                                value={values.email || ""}
                                label={t("email.label")}
                                error={emailError ? true : false}
                                onChange={ChangeValue}
                                name="email"
                            />
                        </label>
                        <FormHelperText>
							{errorMessage ?
								<span>
									{errorMessage}
								</span>
					 		: emailError}
						 </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} className="form-input">
                    <label>
                        <TextField
                            required
                            fullWidth
                            value={values.password || ""}
                            label={t("password.label")}
                            error={passwordError ? true : false}
                            onChange={ChangeValue}
                            name="password"
                            type="password"
                        />
                    </label>
                    <FormHelperText>
						{errorMessage ?
							<span>
								{errorMessage}
							</span>
						: passwordError}
					</FormHelperText>
                </Grid>
                <Grid item container className="login_button_container">
                    <Button
                        className="button login_button"
                        size="small"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit}
                    >
                        {t("btn.login")}
                    </Button>
                </Grid>
			</Grid>
		</Grid>
	);
}

export default React.memo(
	withTranslation()(
		connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent)
	)
);