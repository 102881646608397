import i18n from 'i18next';
import * as en from "./i18n/en";
import * as fr from "./i18n/fr";
import { initReactI18next } from "react-i18next";
import { isUkDomain } from './utils/helpers';
import { ENGLISH_ABV, FRENCH_ABV } from './utils/helpers';

// the translations
const resources = {
    en: {
        translation: en.default,
    },
    fr: {
        translation: fr.default,
    },
};

let lng = localStorage.getItem('language');

if (!lng) {
    if (isUkDomain()) {
        localStorage.setItem('language', ENGLISH_ABV);
        lng = ENGLISH_ABV
    } else {
        localStorage.setItem('language', FRENCH_ABV);
        lng = FRENCH_ABV;
    }
}

i18n.use(initReactI18next).init({
    lng,
    fallbackLng: FRENCH_ABV,
    resources,
    whitelist: [
        FRENCH_ABV, ENGLISH_ABV
    ],
    supportedLngs: [FRENCH_ABV, ENGLISH_ABV],
    keySeparator: false,
    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: '.'
    },
    react: {
        defaultTransParent: 'div',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'],
    },
});

export default i18n;
