import React, { FunctionComponent } from "react";

export interface MainProps {
	children: any;
}

const DashboardPage: FunctionComponent<MainProps> = (props) => {
    return(
        <div>
            Dashboard page
        </div>
    );
}

export default DashboardPage;