import React, {Component, FunctionComponent} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {UiActions} from "../../store/types";
import {AppState} from "../../store/configureStore";
import {ThunkDispatch} from "redux-thunk";
import {bindActionCreators} from "redux";
import {boundRequestChangeSnackBar} from "../../store/actions/uiActions";
import {UiSnackBarInteface} from "../../utils/interfaces";

/**
 * Component global snackbar
 *
 * @component
 *
 * @example
 * return (
 *   <SnackBar snackbar={true/false} message="message" variable="success"/>
 * )
 */

type LinkProps = MainProps & LinkStateProps & LinkDispatchProps;

interface LinkStateProps {
    snackbar: boolean;
    message: string;
    varaible: string;
}

interface LinkDispatchProps {
    boundRequestChangeSnackBar: (data: UiSnackBarInteface) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
    snackbar: state.uiReducer.snackbar,
    message: state.uiReducer.message,
    varaible: state.uiReducer.varaible,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<AppState, {}, UiActions>
) => ({
    boundRequestChangeSnackBar: bindActionCreators(
        boundRequestChangeSnackBar,
        dispatch
    ),
});

export interface MainProps {
    t: any
}

export const SnackBar: FunctionComponent<LinkProps> = (props) => {
    const {snackbar, message, varaible, t} = props;

    const handleClose = () => {
        props.boundRequestChangeSnackBar({
            snackbar: false,
            message: "",
            varaible: "",
        });
    };

    return (
        <Snackbar
            style={{
                whiteSpace: "pre-line",
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            open={snackbar}
            onClose={handleClose}
            message={t(message)}
            autoHideDuration={5000}
            className={varaible}
            action={
                <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            }
        />
    );
};

export default React.memo(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SnackBar))
);
