import React, {FunctionComponent, useState} from "react";
import {withTranslation} from "react-i18next";
import {Fab, FormHelperText} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import "../../assets/sass/reference.scss";
import {Grid} from "@material-ui/core";

export const ReferenceFormItemComponent: FunctionComponent<any> = (props) => {

    const {
        values, imageName, screenshotsName,order, errors,valueRadioButton,
        handleRadioButtonChange, handleUploadImageChange, handleUploadScreenshotsChange, changeValue,handleChangeOrder, data, t
    } = props;
    
    return (
        <Grid item xs={12} sm={12} className="form-container">
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset">
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label={t("reference.title.label")}
                        type="text"
                        fullWidth
                        required
                        error={errors.name ? true : false}
                        value= { values.name }
                        onChange={changeValue}
                    />
                </FormControl>
                <FormHelperText>{errors.name}</FormHelperText>
            </Grid>
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset">
                    <TextField
                        margin="dense"
                        name="website_name"
                        label={t("reference.site.label")}
                        type="text"
                        fullWidth
                        required
                        error={errors.website_name ? true : false}
                        value={values.website_name}
                        onChange={changeValue}
                    />
                </FormControl>
                <FormHelperText>{errors.website_name}</FormHelperText>
            </Grid>
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset">
                    <TextField
                        margin="dense"
                        name="summary"
                        label={t("reference.summary.label")}
                        type="text"
                        fullWidth
                        required
                        error={errors.summary ? true : false}
                        value={values.summary}
                        onChange={changeValue}
                    />
                </FormControl>
                <FormHelperText>{errors.summary}</FormHelperText>
            </Grid>
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset">
                    <TextField
                        margin="dense"
                        name="description"
                        label={t("reference.description.label")}
                        multiline
                        rows={4}
                        fullWidth
                        required
                        error={errors.description ? true : false}
                        value={values.description}
                        onChange={changeValue}
                    />
                </FormControl>
                <FormHelperText>{errors.description}</FormHelperText>
            </Grid>
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset" className="input-file">
                    <FormLabel component="legend">{t("reference.image.label")}</FormLabel>
                    {
                        data ? 
                            <input
                                accept="image/*"
                                name="image"
                                style={{display: 'none'}}
                                type="file"
                                id='upload_image'
                                onChange={handleUploadImageChange}
                                data-error={errors.image ? true : false}
                            />
                        :
                            <input
                                accept="image/*"
                                name="image"
                                style={{display: 'none'}}
                                type="file"
                                id='upload_image'
                                onChange={handleUploadImageChange}
                                required
                                data-error={errors.image ? true : false}
                            />
                    }
                    <label htmlFor="upload_image">
                        <Fab
                            className="button upload"
                            size="small"
                            component="span"
                            aria-label="add"
                            variant="extended"
                        >
                            <AddIcon/> {t("reference.upload.button")}
                        </Fab>
                        <span className="image-name-container">{imageName}</span>
                    </label>
                </FormControl>
                <FormHelperText>{errors.image}</FormHelperText>
            </Grid>
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset" className="radio-botton-container">
                    <FormLabel component="legend">{t("reference.category.label")}</FormLabel>
                    <RadioGroup
                        aria-label="category_name"
                        name="category_name"
                        value={ valueRadioButton }
                        onChange={handleRadioButtonChange}>
                        <FormControlLabel value="1" control={<Radio/>} label="Site web"/>
                        <FormControlLabel value="2" control={<Radio/>} label="Application mobile"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset">
                    <TextField
                        margin="dense"
                        name="intervention_description"
                        label={t("reference.intervention.label")}
                        multiline
                        rows={4}
                        fullWidth
                        required
                        error={errors.intervention_description ? true : false}
                        value=
                            {values.intervention_description}
                        onChange={changeValue}
                    />
                </FormControl>
                <FormHelperText>{errors.intervention_description}</FormHelperText>
            </Grid>
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset">
                    <TextField
                        margin="dense"
                        name="strategy_description"
                        label={t("reference.strategy.label")}
                        multiline
                        rows={4}
                        fullWidth
                        required
                        error={errors.strategy ? true : false}
                        value=
                            { values.strategy_description}
                        onChange={changeValue}
                    />
                </FormControl>
                <FormHelperText>{errors.strategy_description}</FormHelperText>
            </Grid>
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset" className="input-file">
                    <FormLabel component="legend">{t("reference.screenshots.label")}</FormLabel>
                    <input
                        accept="image/*"
                        name="screenshots"
                        style={{display: 'none'}}
                        type="file"
                        id='upload_screenshots'
                        multiple
                        onChange={handleUploadScreenshotsChange}
                        required
                        data-error={errors.screenshots ? true : false}
                    />
                    <label htmlFor="upload_screenshots">
                        <Fab
                            className="button upload"
                            size="small"
                            component="span"
                            aria-label="add"
                            variant="extended"
                        >
                            <AddIcon/> {t("reference.upload.button")}
                        </Fab>
                        <span className="image-name-container">{screenshotsName}</span>
                    </label>
                </FormControl>
                <FormHelperText>{errors.screenshots}</FormHelperText>
            </Grid>
            <Grid item xs={9} sm={9} className="form-input">
                <FormControl component="fieldset">
                    <TextField
                        margin="dense"
                        name="order"
                        label={t("reference.order.label")}
                        type="number"
                        fullWidth
                        error={errors.order? true : false}
                        value= { order}
                        onChange={handleChangeOrder}
                    />
                </FormControl>
            </Grid>

        </Grid>

    );
}

export default React.memo(withTranslation()(ReferenceFormItemComponent));