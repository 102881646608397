import {ReferenceInterface, ReferenceListInterface} from "../../utils/interfaces/referenceInterface";

export const FETCH_REFERENCES_REQUEST = 'FETCH_REFERENCES_REQUEST';
export const FETCH_REFERENCES_SUCCESS = 'FETCH_REFERENCES_SUCCESS';
export const FETCH_REFERENCES_FAILURE = 'FETCH_REFERENCES_FAILURE';

export const GET_REFERENCE_DETAIL = 'GET_REFERENCE_DETAIL';
export const GET_REFERENCE_DETAIL_SUCCESS = 'GET_REFERENCE_DETAIL_SUCCESS';
export const GET_REFERENCE_DETAIL_FAILURE = 'GET_REFERENCE_DETAIL_FAILURE';

export const EDIT_REFERENCE = 'EDIT_REFERENCE';
export const EDIT_REFERENCE_SUCCESS = 'EDIT_REFERENCE_SUCCESS';
export const EDIT_REFERENCE_FAILURE = 'EDIT_REFERENCE_FAILURE';

export const DELETE_REFERENCE = 'DELETE_REFERENCE';
export const DELETE_REFERENCE_SUCCESS = 'DELETE_REFERENCE_SUCCESS';
export const DELETE_REFERENCE_FAILURE = 'DELETE_REFERENCE_FAILURE';

export const ADD_REFERENCE_REQUEST = 'ADD_REFERENCE_REQUEST';
export const ADD_REFERENCE_SUCCESS = 'ADD_REFERENCE_SUCCESS';
export const ADD_REFERENCE_FAILURE = 'ADD_REFERENCE_FAILURE';

interface ReferenceListAsync {
    loading: boolean;
    references: ReferenceListInterface;
    error: string;
}

interface ReferenceDetailAsync {
    loading: boolean;
    reference: ReferenceInterface;
    error: string;
}

interface ReferenceEditedAsync {
    loading: boolean;
    error: string;
}

interface ReferenceDeleteAsync {
    loading: boolean;
    referenceDeleted: ReferenceInterface;
    error: string;
}

interface ReferenceAddAsync {
    loading: boolean;
    error: string;
}

/**
 * reference list get actions
 */
interface FetchReferencesRequest extends ReferenceListAsync {
    type: typeof FETCH_REFERENCES_REQUEST;
}

interface FetchReferencesSuccess extends ReferenceListAsync {
    type: typeof FETCH_REFERENCES_SUCCESS;
}

interface FetchReferencesFailure extends ReferenceListAsync {
    type: typeof FETCH_REFERENCES_FAILURE;
}

/**
 * reference detail show actions
 */
interface GetReferenceDetail extends ReferenceDetailAsync {
    type: typeof GET_REFERENCE_DETAIL;
}

interface GetReferenceDetailSuccess extends ReferenceDetailAsync {
    type: typeof GET_REFERENCE_DETAIL_SUCCESS;
}

interface GetReferenceDetailFailure extends ReferenceDetailAsync {
    type: typeof GET_REFERENCE_DETAIL_FAILURE;
}

/**
 * refernce edit actions
 */

interface EditReference extends ReferenceEditedAsync {
    type: typeof EDIT_REFERENCE;
}

interface EditReferenceSuccess extends ReferenceEditedAsync {
    type: typeof EDIT_REFERENCE_SUCCESS;
}

interface EditReferenceFailure extends ReferenceEditedAsync {
    type: typeof EDIT_REFERENCE_FAILURE;
}

/**
 * reference delete actions
 */
interface DeleteReferenceRequest extends ReferenceDeleteAsync {
    type: typeof DELETE_REFERENCE;
}

interface DeleteReferenceSuccess extends ReferenceDeleteAsync {
    type: typeof DELETE_REFERENCE_SUCCESS;
}

interface DeleteReferenceFailure extends ReferenceDeleteAsync {
    type: typeof DELETE_REFERENCE_FAILURE;
}

/**
 * reference add actions
 */
interface AddReferenceRequest extends ReferenceAddAsync {
    type: typeof ADD_REFERENCE_REQUEST;
}

interface AddReferenceSuccess extends ReferenceAddAsync {
    type: typeof ADD_REFERENCE_SUCCESS;
}

interface AddReferenceFailure extends ReferenceAddAsync {
    type: typeof ADD_REFERENCE_FAILURE;
}

export type ReferenceListActionTypes =
    | FetchReferencesRequest
    | FetchReferencesSuccess
    | FetchReferencesFailure;


export type ReferenceDetailActionTypes =
    | GetReferenceDetail
    | GetReferenceDetailSuccess
    | GetReferenceDetailFailure;

export type ReferenceEditActionTypes =
    | EditReference
    | EditReferenceSuccess
    | EditReferenceFailure;

export type ReferenceDeleteActionTypes =
    | DeleteReferenceRequest
    | DeleteReferenceSuccess
    | DeleteReferenceFailure;

export type ReferenceAddActionTypes =
    | AddReferenceRequest
    | AddReferenceSuccess
    | AddReferenceFailure;