import React, { FunctionComponent } from "react";
import LoginFormComponent from "../components/forms/LoginFormComponent";

export interface MainProps {
}

const LoginPage: FunctionComponent<MainProps> = (props) => {
    return(
        <div>
            <LoginFormComponent />
        </div>
    );
}

export default LoginPage;