import React, { useEffect, useState } from 'react';
import {Switch} from "react-router-dom";
import {Route} from "react-router-dom";
import {LayoutRoute} from "./layouts/LayoutRoute";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import ReferencePage from "./pages/ReferencePage";
import SnackBar from "./components/ui/SnackBar";
import LogoLoader from "./components/ui/LogoLoader";
import './assets/sass/common.scss'

function App() {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);

    return loading ? (
        <LogoLoader />
    ) : (
        <div className="App">
        <SnackBar />
        <Switch>
            <Route exact path="/login"  component={LoginPage}/>
            <LayoutRoute path="/" exact component={DashboardPage}/>
            <LayoutRoute path="/reference" exact component={ReferencePage}/>
        </Switch>
        </div>
    );
}

export default App;
