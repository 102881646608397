import jwt from "jwt-decode";
import { history } from "../utils/helpers/history";

interface MyToken {
	name: string;
	exp: number;
	// whatever else is in the JWT.
}

/**
 * ws gaurd that logs out the user in case of token expiration
 *
 * @param {*} store
 */
export const checkTokenExpirationMiddleware = () => (next: (arg0: any) => void) => (action: any) => {
	const token: string = localStorage.getItem("token") || "";
	let decodedToken = null
	try {
		decodedToken = jwt<MyToken>(token);
	} catch (e) {
		localStorage.clear();
		history.push("/login");
	}

	if (token && decodedToken && decodedToken.exp < Date.now() / 1000) {
		localStorage.clear();
		history.push("/login");
	}

	next(action);
};
