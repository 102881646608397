import {Dispatch} from "redux";
import {referenceFormInterface} from "../../utils/interfaces"
import {methodsServices} from "../services";
import {ReferenceInterface, emptytReference} from '../../utils/interfaces/referenceInterface';
import {
    HANDLE_SNACKBAR,
    GET_REFERENCE_DETAIL,
    GET_REFERENCE_DETAIL_SUCCESS,
    GET_REFERENCE_DETAIL_FAILURE,
    EDIT_REFERENCE,
    EDIT_REFERENCE_SUCCESS,
    EDIT_REFERENCE_FAILURE,
    DELETE_REFERENCE,
    DELETE_REFERENCE_SUCCESS,
    DELETE_REFERENCE_FAILURE,
    ADD_REFERENCE_REQUEST,
    ADD_REFERENCE_SUCCESS,
    ADD_REFERENCE_FAILURE
} from '../models';
import {
    UiActions,
    ReferenceDetailActions,
    ReferenceEditActions,
    ReferenceDeleteActions, ReferenceAddActions
} from '../types';

import {ENDPOINT_REFERENCE} from "../../utils/helpers";
import {receiveReferences} from "./referenceListAction";


export const showSucessSnackBar = (customMesage: string): UiActions => ({
    type: HANDLE_SNACKBAR,
    snackbar: true,
    message: customMesage,
    varaible: "success",
});
export const showErrorSnackBar = (error: string): UiActions => ({
    type: HANDLE_SNACKBAR,
    snackbar: true,
    message: error,
    varaible: "error",
});

export const getReference = (): ReferenceDetailActions => ({
    type: GET_REFERENCE_DETAIL,
    loading: true,
    reference: emptytReference(),
    error: '',

});

export const showSuccessReference = (reference: ReferenceInterface): ReferenceDetailActions => ({
    type: GET_REFERENCE_DETAIL_SUCCESS,
    loading: false,
    reference: reference,
    error: '',
});

export const showErrorReference = (error: string): ReferenceDetailActions => ({
    type: GET_REFERENCE_DETAIL_FAILURE,
    loading: false,
    reference: emptytReference(),
    error: error,
});

export const editReferenceRequest = (): ReferenceEditActions => ({
    type: EDIT_REFERENCE,
    loading: true,
    error: '',

});


export const editReferenceSuccess = (): ReferenceEditActions => ({
    type: EDIT_REFERENCE_SUCCESS,
    loading: false,
    error: '',
});

export const editReferenceError = (error: string): ReferenceEditActions => ({
    type: EDIT_REFERENCE_FAILURE,
    loading: false,
    error: error,
});

export const deleteReferenceRequest = (): ReferenceDeleteActions => ({
    type: DELETE_REFERENCE,
    loading: true,
    referenceDeleted: emptytReference(),
    error: '',

});

export const deleteSuccessReference = (reference: ReferenceInterface): ReferenceDeleteActions => ({
    type: DELETE_REFERENCE_SUCCESS,
    loading: false,
    referenceDeleted: reference,
    error: '',
});

export const deleteErrorReference = (error: string): ReferenceDeleteActions => ({
    type: DELETE_REFERENCE_FAILURE,
    loading: false,
    referenceDeleted: emptytReference(),
    error: error,
});

export const addReferenceRequest = (): ReferenceAddActions => ({
    type: ADD_REFERENCE_REQUEST,
    loading: true,
    error: '',

});
export const addReferenceSuccess = (): ReferenceAddActions => ({
    type: ADD_REFERENCE_SUCCESS,
    loading: false,
    error: '',

});
export const addReferenceError = (error: string): ReferenceAddActions => ({
    type: ADD_REFERENCE_FAILURE,
    loading: false,
    error: error,

});

/**
 * Action Creator to show a reference
 */
export function getReferenceDetail(referenceId: number) {
    return (dispatch: Dispatch<ReferenceDetailActions>) => {
        const referenceEndPoint = ENDPOINT_REFERENCE;
        dispatch(getReference());
        methodsServices
            .get(`${referenceEndPoint}/${referenceId}`, true)
            .then((response) => {
                dispatch(showSuccessReference(response.data.data));
                return response.data.data
            })
            .catch((error) => {
                const errorMsg = error.data.message;
                dispatch(showErrorReference(errorMsg));
            })
    }
}

/**
 * Action Creator to create a new reference
 *
 * @param {Json} reference
 *
 * @returns function contains an action
 */
export function createReference(reference: referenceFormInterface) {
    return (dispatch: Dispatch) => {
        const referenceEndPoint = ENDPOINT_REFERENCE;
        let successMsg = "reference.add.success";
        let snackBarErrorMsg = "reference.add.error";
        let formData = new FormData();

        formData.append("reference_fr", JSON.stringify(reference.fr));
        formData.append("reference_en", JSON.stringify(reference.en));
        formData.append("image", reference.image);
        formData.append("order", reference.order);
        reference.screenshots.forEach((screenshot, index) => {
            formData.append("screenshots[" + index + "]", screenshot);
        });
        dispatch(addReferenceRequest());
        methodsServices
            .post(`${referenceEndPoint}`, formData, true)
            .then((response) => {
                dispatch(addReferenceSuccess());
                dispatch(showSucessSnackBar(successMsg));
                methodsServices
                    .getReferenceList(1, 10, "", true)
                    .then((res) => {
                        dispatch(receiveReferences(res.data.data));
                        return receiveReferences(res.data);
                    });

                return response.data
            })
            .catch((error) => {
                let errorMsg = error.data ? error.data.message : error.message;
                dispatch(addReferenceError(errorMsg));
                dispatch(showErrorSnackBar(snackBarErrorMsg));
            })
    }
}

/**
 * Action Creator to edit a reference
 *
 * @param {Json} reference
 *
 * @returns function contains an action
 */
export function editReference(reference: referenceFormInterface, referenceId: number) {
    return (dispatch: Dispatch) => {
        const referenceEndPoint = ENDPOINT_REFERENCE;
        let successMsg = "reference.edit.success";
        let snackBarErrorMsg = "reference.edit.error";
        let formData = new FormData();

        formData.append("reference_fr", JSON.stringify(reference.fr));
        formData.append("reference_en", JSON.stringify(reference.en));
        formData.append("image", reference.image);
        formData.append("order", reference.order);
        reference.screenshots.forEach((screenshot, index) => {
            formData.append("screenshots[" + index + "]", screenshot);
        });
        dispatch(editReferenceRequest());
        methodsServices
            .post(`${referenceEndPoint}/${referenceId}`, formData, true)
            .then((response) => {
                dispatch(editReferenceSuccess());
                dispatch(showSucessSnackBar(successMsg));
                methodsServices
                    .getReferenceList(1, 10, "", true)
                    .then((res) => {
                        dispatch(receiveReferences(res.data.data));
                        return receiveReferences(res.data);
                    })
                return response.data
            })
            .catch((error) => {
                const errorMsg = error.data ? error.data.message : error.message;
                dispatch(editReferenceError(errorMsg));
                dispatch(showErrorSnackBar(snackBarErrorMsg));
            })
    }
}

/**
 * Action Creator to edit a reference
 *
 * @param {Json} reference
 *
 * @returns function contains an action
 */
export function deleteReference(referenceId: number) {
    return (dispatch: Dispatch) => {
        const referenceEndPoint = ENDPOINT_REFERENCE;
        let successMsg = "reference..delete.success";
        let snackBarErrorMsg = "reference.delete.error";
        dispatch(deleteReferenceRequest());
        methodsServices
            .deleteDetail(`${referenceEndPoint}/${referenceId}`, true)
            .then((response) => {
                dispatch(deleteSuccessReference(response.data));
                dispatch(showSucessSnackBar(successMsg));
                methodsServices
                    .getReferenceList(1, 10, "", true)
                    .then((res) => {
                        dispatch(receiveReferences(res.data.data));
                        return receiveReferences(res.data);
                    })
                return response.data
            })
            .catch((error) => {
                const errorMsg = error.data ? error.data.message : error.message;
                dispatch(deleteErrorReference(errorMsg));
                dispatch(showErrorSnackBar(snackBarErrorMsg));
            })
    }
}