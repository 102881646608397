import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReferenceFormItemComponent from '../forms/ReferenceFormItemComponent'


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent"
    }
}));

export default function ReferenceTabComponent(props: any) {
    const classes = useStyles();
    const [panel, setPanel] = React.useState(0);

    const {
        referenceDataFR, referenceDataEN, imageName, screenshotsName, valueRadioButton, data, handleRadioButtonChange, handleUploadImageChange, handleUploadScreenshotsChange, changeValue, changeLanguage, errorsFR, errorsEN, order, handleChangeOrder
    } = props;

    const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setPanel(newValue);
        newValue == 0 ? changeLanguage('fr') : changeLanguage('en')
    };

    return (
        <div className={classes.root}>
            <Tabs value={panel} onChange={changeTab}>
                <Tab label="Français-FR" {...a11yProps(0)} />
                <Tab label="Anglais-EN" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={panel} index={0}>
                <ReferenceFormItemComponent
                    language='fr'
                    values={referenceDataFR}
                    imageName={imageName}
                    screenshotsName={screenshotsName}
                    order={order}
                    valueRadioButton={valueRadioButton}
                    handleRadioButtonChange={handleRadioButtonChange}
                    handleUploadImageChange={handleUploadImageChange}
                    handleUploadScreenshotsChange={handleUploadScreenshotsChange}
                    changeValue={changeValue}
                    handleChangeOrder={handleChangeOrder}
                    errors={errorsFR}
                    data={data}
                />
            </TabPanel>
            <TabPanel value={panel} index={1}>
                <ReferenceFormItemComponent
                    language='en'
                    values={referenceDataEN}
                    imageName={imageName}
                    screenshotsName={screenshotsName}
                    order={order}
                    valueRadioButton={valueRadioButton}
                    handleRadioButtonChange={handleRadioButtonChange}
                    handleUploadImageChange={handleUploadImageChange}
                    handleUploadScreenshotsChange={handleUploadScreenshotsChange}
                    changeValue={changeValue}
                    handleChangeOrder={handleChangeOrder}
                    errors={errorsEN}
                    data={data}
                />
            </TabPanel>
        </div>
    );
}