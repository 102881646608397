
import {HANDLE_SNACKBAR, UiActionTypes,} from '../models';


interface UiState {
  snackbar: boolean,
  message: string,
  varaible: string,
}

const defaultState: UiState = {
  snackbar: false,
  message: "",
  varaible: "",
};

export const uiReducer = (
  state = defaultState,
  action: UiActionTypes
): UiState => {
  switch (action.type) {
    case HANDLE_SNACKBAR:
      return {
        snackbar: action.snackbar,
        message: action.message,
        varaible: action.varaible,
      };
    default:
      return state;
  }
};