//ui snackbar
export const HANDLE_SNACKBAR = 'HANDLE_SNACKBAR';
interface uiAsync {
    snackbar: boolean,
    message: string,
    varaible: string,
}
interface handleSnackBar extends uiAsync {
    type: typeof HANDLE_SNACKBAR;
}

export type UiActionTypes =
    | handleSnackBar