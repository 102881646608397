import { Dispatch } from 'redux';
import { LoginFormActions, LogoutActions } from '../types';
import { methodsServices } from "../services/methodsService";
import { history } from "../../utils/helpers";
import {
    SEND_LOGIN_FORM_REQUEST,
    SEND_LOGIN_FORM_SUCCESS,
    SEND_LOGIN_FORM_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE
} from '../models/authActions';
import { LoginFormInterface } from '../../utils/interfaces/loginFormInterface';
import { getFirstErrorFromResponse } from '../../utils/helpers/tools';

export const requestLoginForm = (): LoginFormActions => ({
    type: SEND_LOGIN_FORM_REQUEST,
    loading: true,
    form: {} as LoginFormInterface,
    error: '',
});

export const receiveLoginForm = (): LoginFormActions => ({
    type: SEND_LOGIN_FORM_SUCCESS,
    loading: false,
    form: {} as LoginFormInterface,
    error: '',
});

export const errorLoginForm = (error: string): LoginFormActions => ({
    type: SEND_LOGIN_FORM_FAILURE,
    loading: false,
    form: {} as LoginFormInterface,
    error: error,
});

export const boundRequestSendLoginForm = (form: LoginFormInterface) => {
    let formData = new FormData();
    formData.append("email", form.email);
    formData.append("password", form.password);

    return (dispatch: Dispatch<LoginFormActions>) => {
        dispatch(requestLoginForm());
        return methodsServices
        .sendLoginForm(formData)
        .then((res) => {
            localStorage.setItem('token', res.data.user.access_token );
            dispatch(receiveLoginForm());
            return receiveLoginForm();
        }).catch((error) => {
            const errorMsg = getFirstErrorFromResponse(error);
            dispatch(errorLoginForm(errorMsg));
            return errorLoginForm(errorMsg);
        })
    }
};

export const requestLogout = () : LogoutActions => ({
    type: LOGOUT_REQUEST,
    loading: true,
    error: '',
});

export const receiveLogout = (): LogoutActions => ({
    type: LOGOUT_SUCCESS,
    loading: false,
    error: '',
});

export const errorLogout = (error: string): LogoutActions => ({
    type: LOGOUT_FAILURE,
    loading: false,
    error: error,
});

export const boundRequestLogout = () => {
    return (dispatch: Dispatch<LogoutActions>) => {
        dispatch(requestLogout());
        return methodsServices
        .logout(true)
        .then((res) => {
            localStorage.removeItem("token");
            dispatch(receiveLogout());
            return receiveLogout();
        }).catch((error) => {
            const errorMsg = getFirstErrorFromResponse(error);
            dispatch(errorLogout(errorMsg));
            history.push("/login");
            return errorLogout(errorMsg);
            
        })
    }
}



