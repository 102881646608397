import React, { FunctionComponent } from "react";
import Logo from "./svg/Logo";

/**
 * Component LogoLoader
 *
 * @component
 *
 * @example
 * return (
 *   <LogoLoader/>
 * )
 */

export const LogoLoader: FunctionComponent<any> = () => {
	return (
		<div className="logo-loader-container">
			<Logo />
		</div>
	);
};

export default React.memo(LogoLoader);
