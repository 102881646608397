import React, {FunctionComponent, useState, useEffect} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../store/configureStore";
import {ReferenceEditActions} from "../../store/types";
import {editReference} from "../../store/actions/referenceAction";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
    MAX_LENGTH,
    DESCRIPTION_MAX_LENGTH,
    SUMMARY_MAX_LENGTH,
    IMAGE_MAX_SIZE,
    SCREENSHOTS_MAX_SIZE

} from "../../utils/helpers";
import ReferenceTabComponent from "../reference/ReferenceTabComponent";
import Loader from "../ui/Loader";
import "../../assets/sass/reference.scss";
import {
    referenceFormInterface,
    referenceInterfaceData,
    referenceErrorsInterface,
    ReferenceInterface,
} from "../../utils/interfaces";

export interface MainProps {
    t: any,
    i18n: any;
    open: boolean,

    handleCloseDialog(): void,

    data: ReferenceInterface,
    referenceId: number
}

interface LinkStateProps {
    loading: boolean;
}

interface LinkDispatchProps {
    editReference: (reference: referenceFormInterface, referenceId: number) => void,
}

type LinkProps = LinkStateProps & LinkDispatchProps & MainProps;

const mapStateToProps = (state: AppState): LinkStateProps => ({
    loading: state.referenceEditReducer.loading,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<AppState, {}, ReferenceEditActions>
) => ({
    editReference: bindActionCreators(editReference, dispatch)
});

export const EditReferenceFormComponent: FunctionComponent<LinkProps> = (props) => {
    const {t, i18n, open, handleCloseDialog, data, referenceId, loading} = props;
    const [language, setLanguage] = useState('fr');
    const [referenceDataFR, setReferenceDataFR] = useState({
        name: '',
        summary: '',
        description: '',
        intervention_description: '',
        strategy_description: '',
        website_name: '',
        category_name: '1'
    });
    const [referenceDataEN, setReferenceDataEN] = useState({
        name: '',
        summary: '',
        description: '',
        intervention_description: '',
        strategy_description: '',
        website_name: '',
        category_name: '1'
    });
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState("");
    const [imageSize, setImageSize] = useState(0);
    const [screenshots, setScreenshots] = useState<Array<any>>([]);
    const [screenshotsName, setScreenshotsName] = useState("");
    const [screenshotsSize, setScreenshotsSize] = useState(0);
    const [valueRadioButton, setValueRadioButton] = React.useState("1");
    const [order, setOrder] = React.useState('');

    const imageNameString = (imageString: string) => {
        let ch = [];
        let res = "";
        ch = (imageString.substring(20)).split('_');
        res = ch[ch.length - 1];

        return (res);
    }

    const screenshotsString = (screenshotsArray: Array<string>) => {
        let ch = "";
        let endSc = "";
        for (const sc  of screenshotsArray) {
            if (ch === "") {
                ch = sc.substring(20)
                endSc = imageNameString(ch);

            } else {
                ch = sc.substring(20)
                endSc = endSc + ', ' + imageNameString(ch);
            }
        }
        return endSc;
    }

    const DefaultRadioButton = (value: string) => {
        let radioValue = "1";
        if (value === "Application mobile") {
            radioValue = "2"
        }
        return radioValue;
    }

    useEffect(() => {
        setReferenceDataFR({
            name: data.name.fr,
            summary: data.summary.fr,
            description: data.description.fr,
            intervention_description: data.intervention_description.fr,
            strategy_description: data.strategy_description.fr,
            website_name: data.website_name,
            category_name: (data.category_name.fr === "Application mobile") ? "2" : "1"
        });
        setReferenceDataEN({
            name: data.name.en,
            summary: data.summary.en,
            description: data.description.en,
            intervention_description: data.intervention_description.en,
            strategy_description: data.strategy_description.en,
            website_name: data.website_name,
            category_name: (data.category_name.en === "Mobile App") ? "2" : "1"
        });
        setImageName(imageNameString(data.image));
        setScreenshotsName(screenshotsString(data.screenshot_images));
        setValueRadioButton(DefaultRadioButton(data.category_name.fr));
        setOrder(data.order ? data.order : '');
    }, [props])

    const [errorsFR, setErrorsFR] = useState<referenceErrorsInterface>(
        {} as referenceErrorsInterface
    );
    const [errorsEN, setErrorsEN] = useState<referenceErrorsInterface>(
        {} as referenceErrorsInterface
    );

    const validationForm = (values: referenceInterfaceData): referenceErrorsInterface => {
        let errorObj = {} as referenceErrorsInterface;
        if (!values.name) {
            errorObj.name = t("reference.add.name.required");
        } else if (values.name.length > MAX_LENGTH) {
            errorObj.name = t("reference.add.name.long");
        }
        if (!values.summary) {
            errorObj.summary = t("reference.add.summary.required");

        } else if (values.summary.length > SUMMARY_MAX_LENGTH) {
            errorObj.summary = t("reference.add.summary.long");
        }
        if (!values.description) {
            errorObj.description = t("reference.add.description.required");
        } else if (values.description.length > DESCRIPTION_MAX_LENGTH) {
            errorObj.description = t("reference.add.description.long");
        }
        if (!values.website_name) {
            errorObj.website_name = t("reference.add.website.required");
        } else if (values.website_name.length > MAX_LENGTH) {
            errorObj.website_name = t("reference.add.website.long");
        }

        if (!values.category_name) {
            errorObj.category_name = t("reference.add.category.required");
        }
        if (!values.intervention_description) {
            errorObj.intervention_description = t("reference.add.intervention.required");
        } else if (values.intervention_description.length > DESCRIPTION_MAX_LENGTH) {
            errorObj.intervention_description = t("reference.add.intervention.long");
        }
        if (!values.strategy_description) {
            errorObj.strategy_description = t("reference.add.strategy.required");
        } else if (values.strategy_description.length > DESCRIPTION_MAX_LENGTH) {
            errorObj.strategy_description = t("reference.add.strategy.long");
        }
        if (imageSize > IMAGE_MAX_SIZE) {
            errorObj.image = t("reference.add.image.big");
        }
        if (screenshotsSize > SCREENSHOTS_MAX_SIZE) {
            errorObj.screenshots = t("reference.add.screenshots.big");
        }
        return errorObj;
    }

    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueRadioButton((event.target as HTMLInputElement).value);
    };

    const handleUploadImageChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
            setImageName(file.name);
            setImageSize(file.size)
        }
    };

    const handleUploadScreenshotsChange = (event: any) => {
        let files = event.target.files;
        let allNames = "";
        let filesArray = [];
        let fileSize = 0;
        for (let file of files) {
            filesArray.push(file);
            fileSize = fileSize + file.size;
            if (!allNames) {
                allNames = file.name;
            } else {
                allNames = allNames + ", " + file.name;
            }
        }
        setScreenshots(filesArray);
        setScreenshotsName(allNames);
        setScreenshotsSize(fileSize);
    };

    const emptyFields = () => {
        setErrorsFR({} as referenceErrorsInterface);
        setErrorsEN({} as referenceErrorsInterface);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const errorsObjFR = validationForm(referenceDataFR);
        const errorsObjEN = validationForm(referenceDataEN);
        const formReferenceId = referenceId;
        setErrorsFR(errorsObjFR);
        setErrorsEN(errorsObjEN);
        const isValid = !Object.keys(errorsObjFR).length && !Object.keys(errorsObjEN).length;
        if (isValid) {
            const referenceData: referenceFormInterface = {
                "fr": referenceDataFR,
                "en": referenceDataEN,
                "image": image,
                "screenshots": screenshots,
                "order": order
            };
            props.editReference(referenceData, formReferenceId);
            props.handleCloseDialog();
            emptyFields();
        }
    }

    const handleCloseModal = () => {
        props.handleCloseDialog();
        emptyFields();
    }

    const changeValue = (event: any) => {
        if (language == 'fr') {
            setReferenceDataFR({
                ...referenceDataFR,
                [event.target.name]: event.target.value,
            })
            setErrorsFR({...errorsFR, [event.target.name]: ""})
        } else {
            setReferenceDataEN(
                {
                    ...referenceDataEN,
                    [event.target.name]: event.target.value,

                }
            )
            setErrorsEN({...errorsEN, [event.target.name]: ""})
        }

    };
    const handleChangeOrder = (event: any) => {
        setOrder(event.target.value);
    }
    const changeLanguage = (value: string) => {
        setLanguage(value)
    }

    return (
        <div className="add-reference-container">
            <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title"
                    className="dialog-content">
                <DialogTitle id="form-dialog-title">
                    <div className="title"> {t("reference.edi.modal.title")} {data.name.fr}</div>
                </DialogTitle>
                <DialogContent>
                    <ReferenceTabComponent
                        referenceDataFR={referenceDataFR}
                        referenceDataEN={referenceDataEN}
                        language={language}
                        changeLanguage={changeLanguage}
                        imageName={imageName}
                        order={order}
                        screenshotsName={screenshotsName}
                        valueRadioButton={valueRadioButton}
                        handleRadioButtonChange={handleRadioButtonChange}
                        handleUploadImageChange={handleUploadImageChange}
                        handleUploadScreenshotsChange={handleUploadScreenshotsChange}
                        changeValue={changeValue}
                        handleChangeOrder={handleChangeOrder}
                        errorsFR={errorsFR}
                        errorsEN={errorsEN}
                        data={data}
                    />

                    <DialogActions>
                        <Button onClick={handleCloseModal} className="button add-reference">
                            {t("reference.close.modal.button")}
                        </Button>
                        <Button
                            className="button add-reference"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading && <Loader size={15} containerMinHeight="100%"/>}
                            {t("reference.edit.modal.button")}

                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default React.memo(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(EditReferenceFormComponent)
    )
);