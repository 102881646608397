import { Dispatch } from 'redux';
import { UiActions } from '../types';
import {
    HANDLE_SNACKBAR,
} from '../models';
import { UiSnackBarInteface } from '../../utils/interfaces';

export const changeUi = (action: UiSnackBarInteface): UiActions => ({
    type: HANDLE_SNACKBAR,
    snackbar: action.snackbar,
    message: action.message,
    varaible: action.varaible,
});

 export const boundRequestChangeSnackBar = (data: UiSnackBarInteface) => {
    return (dispatch: Dispatch<UiActions>) => {
        dispatch(changeUi(data));
        return changeUi(data);
    }
};
