import { Dispatch } from 'redux';
import { ReferenceListActions } from '../types';
import { methodsServices } from "../services/methodsService";
import { ReferenceListInterface, emptyReferences } from '../../utils/interfaces/referenceInterface';

import {
    FETCH_REFERENCES_REQUEST,
    FETCH_REFERENCES_SUCCESS,
    FETCH_REFERENCES_FAILURE
} from '../models';
import {getFirstErrorFromResponse} from '../../utils/helpers';

export const requestReferences = (): ReferenceListActions => ({

  type: FETCH_REFERENCES_REQUEST,
  loading: true,
  references: emptyReferences(),
  error: '',

});

export const receiveReferences = (references: ReferenceListInterface): ReferenceListActions => ({
    type: FETCH_REFERENCES_SUCCESS,
    loading: false,
    references: references,
    error: '',
});

const invalidateReferences = (error: string): ReferenceListActions => ({
  type: FETCH_REFERENCES_FAILURE,
  loading: false,
  references: emptyReferences(),
  error: error,
});

export const boundRequestReferences = (page: number, perPage: number, searchKey: string) => {
  return (dispatch: Dispatch<ReferenceListActions>) => {
    dispatch(requestReferences());
    methodsServices
      .getReferenceList(page, perPage, searchKey, true)
      .then((res) => {
        dispatch(receiveReferences(res.data.data));
        return receiveReferences(res.data);
      }).catch((error) => {
        const errorMsg = getFirstErrorFromResponse(error);
        dispatch(invalidateReferences(errorMsg));
        return invalidateReferences(errorMsg);
      })
  }
};