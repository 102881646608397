import React, {FunctionComponent} from "react";
import {useDispatch} from "react-redux";
import AddReferenceFormComponent from "../components/forms/AddReferenceFormComponent";
import EditReferenceFormComponent from "../components/forms/EditReferenceFormComponent";
import {createReference} from "../store/actions";
import {referenceFormInterface} from "../utils/interfaces";
import ReferenceDataTableComponent from "../components/dataTables/ReferencesDataTableComponent";


export interface MainProps {
    children: any;
}

const ReferencePage: FunctionComponent<MainProps> = (props) => {
    const dispatch = useDispatch();
    // call to the action method to create a reference
    const addReference = (value: referenceFormInterface) => {
        dispatch(createReference(value));
    };

    return (
        <div className="page-container">
            <AddReferenceFormComponent addReference={addReference}/>
            <ReferenceDataTableComponent/>
        </div>
    );
}

export default ReferencePage;