import { ReferenceTableHeadCell} from "../interfaces/referenceInterface";

export const referenceHeadCells: ReferenceTableHeadCell[] = [
    { id: 'name', numeric: false,  label: 'reference.header.title' },
    { id: 'summary', numeric: false,  label: 'reference.header.summary' },
    { id: 'description', numeric: false,  label: 'reference.header.description' },
    { id: 'order', numeric: false,  label: 'reference.header.order' },
    { id: 'image', numeric: false,  label: 'reference.header.image' },
    { id: 'website_name', numeric: false,  label: 'reference.header.website' },
    { id: 'id', numeric: true,  label: 'reference.header.actions' },
];