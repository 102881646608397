import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import {
    loginFormReducer, logoutReducer
} from "./authReducer";
import {
    referenceListReducer, referenceEditReducer, referenceDeleteReducer, referenceDetailReducer,referenceAddReducer
} from "./referenceReducer";
import {
    uiReducer
} from "./uiReducer";
import { history } from "../../utils/helpers/history";


const rootReducer = 
    combineReducers({
        router: connectRouter(history),
        loginFormReducer, logoutReducer, referenceListReducer: referenceListReducer,
        uiReducer, referenceDetailReducer, referenceEditReducer, referenceDeleteReducer,referenceAddReducer
    });

export default rootReducer;
