import {Divider} from "@material-ui/core";
import React, {ComponentType, FC} from "react";
import {Route, RouteProps, Redirect} from "react-router-dom";
import Layout from "../pages/layaouts/layout";
import {isLoggedIn} from "../utils/helpers/tools";

/**
 * Component LayoutRoute
 *
 * @component
 *
 * @example
 * return (
 *   <LayoutRoute path="/" exact component={componentName}/>
 * )
 */


interface IProps extends RouteProps {
    component: ComponentType<any>;
    status?: number;
    path: string;

}

export const LayoutRoute: FC<IProps> = ({
                                            component: Comp,
                                            status,
                                            path,
                                            ...rest
                                        }) => {
    return (
        <Route
            {...rest}
            render={(matchProps) => {
                if (matchProps && status) {
                    matchProps.staticContext = {
                        ...matchProps.staticContext,
                        statusCode: status,
                    };
                }

                return (
                    <div>
                        {
                            isLoggedIn() ? <Layout route={path} children={<Comp {...matchProps} />}/> :
                                <Redirect to="/login"/>
                        }
                    </div>

                );
            }}
        />
    );
};
