import React, {FunctionComponent, useState, useEffect} from 'react';
import {withTranslation} from "react-i18next";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {useHistory} from "react-router-dom";
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BallotIcon from '@material-ui/icons/Ballot';
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {AppState} from "../../store/configureStore";
import {LogoutActions} from "../../store/types";
import {boundRequestLogout} from "../../store/actions";
import {DropdownComponent} from "../../components/dropdown/DropdownComponent";


import '../../assets/sass/layout.scss'
import {useStyles} from './styles'

interface MainProps {
    t:any,
    children: any,
    route: string,
}

interface LinkStateProps {
    loading: boolean;
    error: string;
}

interface LinkDispatchProps {
    boundRequestLogout: () => any;
}

type LinkProps = MainProps & LinkStateProps & LinkDispatchProps;

const mapStateToProps = (state: AppState): LinkStateProps => ({
    loading: state.logoutReducer.loading,
    error: state.logoutReducer.error,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<AppState, {}, LogoutActions>
) => ({
    boundRequestLogout: bindActionCreators(boundRequestLogout, dispatch),
});

export const Layout: FunctionComponent<LinkProps> = (props) => {
    const { t } = props;
    const [open, setOpen] = useState(true);
    const [drawerWidth, setDrawerWidth] = useState(220);
    const [screenWidth, setScreenWidth] = useState(window.screen.width);

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [screenWidth]);

    const classes = useStyles();
    const history = useHistory();
    const {children, route} = props;

    const handleDrawerOpen = () => {
        setOpen(true);
        setDrawerWidth(220);
    };
    const handleDrawerClose = () => {
        setOpen(false);
        setDrawerWidth(56);
    };

    const logout = () => {
        props
            .boundRequestLogout()
            .then((res: any) => {
                if (!res.error) {
                    history.push('/login');
                }
            });

    }

    return (
        <div className={classes.root}>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <div className="toolbar-left-side">
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <div
                            className="logo"
                            style={{
                                marginLeft: `${((screenWidth > 700 && !open)) ? '24px' : '0'}`,
                                width: `${((screenWidth < 600 && open)) ? '70px' : '150px'}`,
                                height: `${((screenWidth < 600 && open)) ? '55px' : '50px'}`,
                                marginRight: `${((screenWidth < 600 && open)) ? '5px' : '0'}`
                            }}
                        />
                    </div>
                    <DropdownComponent drawerOpened={open}/>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton
                        onClick={handleDrawerClose}
                        style={{display: `${open ? '' : 'none'}`}}
                    >
                        <ChevronLeftIcon className={classes.menuIcon}/>
                    </IconButton>
                </div>
                <Divider/>
                <List className='drawer-list'>
                    <ListItem button onClick={() => history.push('/')}
                              className={route === '/' ? 'isactive' : ''}>
                        <ListItemIcon>
                            <DashboardIcon className={classes.menuIcon}/>
                        </ListItemIcon>
                        <ListItemText>
                            <span>{t("menu.dashboard")}</span>
                        </ListItemText>
                    </ListItem>

                    <ListItem button onClick={() => history.push('/reference')}
                              className={route === '/reference' ? 'isactive' : ''}>
                        <ListItemIcon>
                            <BallotIcon className={classes.menuIcon}/>
                        </ListItemIcon>
                        <ListItemText>
                            <span>{t("menu.reference")}</span>
                        </ListItemText>
                    </ListItem>

                    <ListItem button onClick={logout}
                              className={route === '/login' ? 'isactive' : ''}>
                        <ListItemIcon>
                            <ExitToAppIcon className={classes.menuIcon}/>
                        </ListItemIcon>
                        <ListItemText>
                            <span>{t("menu.logout")}</span>
                        </ListItemText>
                    </ListItem>

                </List>
            </Drawer>
            <div className='content-page'
                 style={{width: `calc(100% - ${drawerWidth}px)`}}
            >{children}</div>
        </div>
    );
}

export default React.memo(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(Layout)
    )
);
