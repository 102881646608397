import { LoginFormInterface } from '../../utils/interfaces';

//login form
interface LoginFormAsync {
    loading: boolean;
    form: LoginFormInterface;
    error: string;
}

export const SEND_LOGIN_FORM_REQUEST = 'SEND_LOGIN_FORM_REQUEST';
export const SEND_LOGIN_FORM_SUCCESS = 'SEND_LOGIN_FORM_SUCCESS';
export const SEND_LOGIN_FORM_FAILURE = 'SEND_LOGIN_FORM_FAILURE';

interface SendLoginFormRequest extends LoginFormAsync {
    type: typeof SEND_LOGIN_FORM_REQUEST;
}
interface SendLoginFormSuccess extends LoginFormAsync {
    type: typeof SEND_LOGIN_FORM_SUCCESS;
}
interface SendLoginFormFailure extends LoginFormAsync {
    type: typeof SEND_LOGIN_FORM_FAILURE;
}

export type LoginFormActionTypes =
  | SendLoginFormRequest
  | SendLoginFormSuccess
  | SendLoginFormFailure;

//logout
interface LogoutAsync {
    loading: boolean;
    error: string;
}

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

interface LogoutRequest extends LogoutAsync {
    type: typeof LOGOUT_REQUEST;
}
interface LogoutSuccess extends LogoutAsync {
    type: typeof LOGOUT_SUCCESS;
}
interface LogoutFailure extends LogoutAsync {
    type: typeof LOGOUT_FAILURE;
}

export type LogoutActionTypes =
  | LogoutRequest
  | LogoutSuccess
  | LogoutFailure;
